import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  page: <MIcon src={path('ic_page')} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Gestão de Concessões',
    items: [
      {
        title: 'Módulos do sistema',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root,
        items: [
          {
            title: 'Gestão de Concessões',
            href: PATH_APP.general.root
          },
          {
            title: 'Gestão de Licitações',
            href: PATH_APP.modulos.licitacoes
          },
          {
            title: 'Gestão de Concessionários',
            href: PATH_APP.modulos.concessionarios
          },
          {
            title: 'Monitoramento de Contratos',
            href: PATH_APP.modulos.monitorcontratos
          },
          {
            title: 'Arrecadação Consolidada',
            href: PATH_APP.modulos.arrecadacao
          },
          {
            title: 'Relatórios',
            href: PATH_APP.modulos.relatorios
          },
          {
            title: 'Gestão de Contratos',
            href: PATH_APP.modulos.contratos
          },
          {
            title: 'Visitas de Campo',
            href: PATH_APP.modulos.visitas
          },
          {
            title: 'Central de Comunicação',
            href: PATH_APP.modulos.centralcomunicacao
          },
          {
            title: 'Central do Administrador',
            href: PATH_APP.modulos.centraladmin
          }
        ]
      }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'Item One',
  //       href: PATH_APP.app.itemOne,
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'Item Two',
  //       href: PATH_APP.app.itemTwo,
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'Item Three',
  //       href: PATH_APP.app.itemThree,
  //       icon: ICONS.page
  //     }
  //   ]
  // }
];

export default navConfig;

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login')
  },
  maintenance: '/maintenance',
  payment: '/payment'
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  app: {
    itemOne: path(ROOTS.app, '/item-one'),
    itemTwo: path(ROOTS.app, '/item-two'),
    itemThree: path(ROOTS.app, '/item-three')
  },
  modulos: {
    florestas: path(ROOTS.app, '/florestas-publicas'),
    formfloresta: path(ROOTS.app, '/florestas-publicas/cadastroFloresta'),
    florestainfo: path(ROOTS.app, '/florestas-publicas/visualizarFloresta'),
    unidadesmanejo: path(ROOTS.app, '/florestas-publicas/gerenciarUMFs'),
    licitacoes: path(ROOTS.app, '/licitacoes'),
    concessionarios: path(ROOTS.app, '/concessionarios'),
    monitorcontratos: path(ROOTS.app, '/monitoramento-contratos'),
    arrecadacao: path(ROOTS.app, '/arrecadacao-consolidada'),
    relatorios: path(ROOTS.app, '/relatorios'),
    contratos: path(ROOTS.app, '/contratos'),
    visitas: path(ROOTS.app, '/visitas-campo'),
    centralcomunicacao: path(ROOTS.app, '/central-comunicaco'),
    centraladmin: path(ROOTS.app, '/central-administrador')
  }
};
